module.exports = {
  app: {
    WALLET: 'Connect the purse',
  },
  nav: {
    HOME: 'HOME',
    FARM: 'FARM',
    EXCHANGE: 'EXCHANGE',
    GAME: 'GAME',
    BRIDGE: 'BRIDGE',
    MARKET: 'NFT MARKET',
    TWITTER: 'Twitter',
    GITHUB: 'GITHUB',
    WHITEBOOK: 'WHITE PAPER',
    AUDIT: 'AUDIT'
  },
  home: {
    BALANCE: 'Your SFI Quantity',
    SUPPLY: 'SFI Quantity',
    BURNED: 'Total PIPI burned since launch',
    DISTRIBUTED: 'Distributed market value',
    USERVALUE: 'Your SFI value',
    VLAUE: 'SFI value'
  },
  farm: {
    UNLOCK: 'Unlock Wallet',
    DETAILS: 'details',
    EARNED: 'SFI Earned',
    LPSTAKED: 'LPSTAKED',
    TAKE: 'TAKE', // ?
    GETBENIFIT: 'Get Benefit',
    CANWITHDRAW: 'Can Withdraw',
    PLEDGE: 'Pledge',
    WITHDRAW: 'Withdraw',
    LIQUIDITY: 'Liquidity',
    GET: 'Get',
    VIEW: 'View on BscScan'
  },
  exchange: {
    RULES: 'The Exchange Rules',
    LINE1: '10 million SFI were issued through exchange, and users participated in the exchange using USDT.',
    LINE2: 'The exchange is divided into three layers. When the exchange of the layer is completed, the smart contract automatically opens the exchange of the next layer, and the exchange price increases step by step according to the regulations.',
    LINE3: 'The minimum unit of exchange is 2,000 SFI, and the amount of each exchange is several times that of 2000. The maximum amount of exchange for one wallet address is 20 SFI.',
    LINE4: 'After successful exchange, we will get the SFI you exchanged with USDT in our wallet.',
    LAYER1: 'The first layer',
    LAYER2: 'The second layer',
    LAYER3: 'The third layer',
    PRICE1: '3.34 million pieces unit price',
    PRICE2: '3.33 million pieces unit price',
    PRICE3: '3.33 million pieces unit price',
    BALANCE: 'The balance of USDT',
    EXCHANGE: 'Exchange',
    SINGLE: 'Single input up to 20',
    EXPLAIN: 'To use USDT to exchange SFI, the exchange amount must be an integer multiple of 1000',
    CURRENT1: 'The current price is',
    CURRENT2: 'it will go up to',
    APPROVAL: 'approval',
    BEGIN: 'Presale has begun',
    END: 'Presale is over',
    BUY: 'buy',
    DAYS: 'd',
    HOURS: 'h',
    MINUTES: 'min',
    SECONDES: 's'
  },
  game: {
    COMING: 'Comming Soon...',
    INTRODUCTION: "This is an underwater world expedition, here you can display a variety of fishing skills, enjoy the fun of catching big fish, but not all the fish are friendly, they will use their own way to protect themselves, defend their own underwater world.  Of course, it's also a place of adventure and opportunity, with hidden treasures waiting to be discovered."
  }
};