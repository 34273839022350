<template>
  <div class="logo-box position-relative">
    <img src="../assets/img/bubble.png" alt="">
    <img src="../assets/img/bubble.png" alt="">
  </div>
</template>

<script>

export default {
  data() {
    return {}
  }
}

</script>

<style lang="less" scoped>

@keyframes logo {
  0% {
    background-image: url('../assets/img/logo1.png');
  }
  30% {
    background-image: url('../assets/img/logo2.png');
  }
  100% {
    background-image: url('../assets/img/logo1.png');
  }
}

@keyframes bubble {
  0% {
    opacity: 0;
  }
  50% {
     opacity: 0;
  }
  51% {
     opacity: 1;
  }
  100% {
     opacity: 1;
  }
}

.logo-box {
  animation: logo 1.2s linear infinite both;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 3rem;
  height: 3rem;
  img {
    position: absolute;
    &:nth-of-type(1) {
      top: -0.15rem;
      right: -0.15rem;
      width: 20%;
      animation: bubble 1s linear infinite both;
    }
    &:nth-of-type(2) {
      top: 0.3rem;
      right: 0.3rem;
      width: 10%;
      animation: bubble 1s linear infinite reverse both;
    }
  }
  
}

</style>