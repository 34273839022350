import { Message, MessageBox } from 'element-ui';
import store from './store';
import { ethers } from 'ethers';
import IERC20 from "./ABI/BEP20USDT.json";
import RushToBuy from "./ABI/RushToBuy.json";
import SFI from "./ABI/SFI.json";
import Pair from "./ABI/PancakePair.json";
import MiningContract from "./ABI/MiningContract.json";


let RushToBuy_Address = '0xB844068D54a476A1A8101AFcBc223c075Bd3a6E1';
let USDT_Address = '0x55d398326f99059fF775485246999027B3197955';
let SFI_Address = "0x70aE49fc515E8486AEc3b1BF832c5D957A318278";

// let RushToBuy_Address = '0x3e3064CC8E707b6b6d2b3cf37B345C64A856F57a';
// let USDT_Address = '0x7ef95a0FEE0Dd31b22626fA2e10Ee6A223F8a684';
// let SFI_Address = "0x5948c09d614a0DdE80D049685FCc1924f71cEb5A";


let Provider;
let Account;
let networkID;
let Signer;
let BSCWEB = "https://testnet.bscscan.com/address/"


let SFI_BUSD_PairAddress
let SFI_BNB_PairAddress
let BNB_BUSD_PairAddress
let TPT_BUSD_PairAddress
let BUSD_USDT_PairAddress

export let SFI_Mining
export let SFI_BUSD_Pair_Mining
export let SFI_BNB_Pair_Mining
export let BNB_BUSD_Pair_Mining
export let TPT_BUSD_Pair_Mining
export let BUSD_USDT_Pair_Mining


// 初始化方法。页面加载时运行
export const connWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
        Provider = await new ethers.providers.Web3Provider(window.ethereum);
        // console.log(Provider);
        await Provider.getNetwork().then((network) => {
            networkID = network.chainId;
            // console.log("Network", networkID);
        })
        //console.log("Network", networkID);
        if (networkID != 56) { // 56    97
            Message.error('错误的网络,请连接BSC主网');
        } else {
            let eth_requestAccounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
            Account = await eth_requestAccounts[0]

            Signer = await Provider.getSigner();

            let Token = new ethers.Contract(USDT_Address, IERC20.abi, Provider)
            let allowances = await Token.allowance(Account, RushToBuy_Address) + 0
            let balance = await Token.balanceOf(Account) + 0
            //console.log(allowances,balance, typeof(allowances), '~~~~~')
            if (+allowances > +balance) {
                //console.log(allowances,balance)
                store.state.approved = true;
            }

            // let SFI_Contract = new ethers.Contract(SFI_Address, SFI.abi, Signer)
            // SFI_BUSD_PairAddress = await SFI_Contract.SFI_BUSD_PairAddress()
            // SFI_BNB_PairAddress = await SFI_Contract.SFI_BNB_PairAddress()
            // BNB_BUSD_PairAddress = await SFI_Contract.BNB_BUSD_PairAddress()
            // TPT_BUSD_PairAddress = await SFI_Contract.TPT_BUSD_PairAddress()
            // BUSD_USDT_PairAddress = await SFI_Contract.BUSD_USDT_PairAddress()

            // SFI_Mining = await SFI_Contract.SFI_Mining()
            // SFI_BUSD_Pair_Mining=await SFI_Contract.SFI_BUSD_Pair_Mining()
            // SFI_BNB_Pair_Mining=await SFI_Contract.SFI_BNB_Pair_Mining()
            // BNB_BUSD_Pair_Mining=await SFI_Contract.BNB_BUSD_Pair_Mining()
            // TPT_BUSD_Pair_Mining=await SFI_Contract.TPT_BUSD_Pair_Mining()
            // BUSD_USDT_Pair_Mining=await SFI_Contract.BUSD_USDT_Pair_Mining()


            // 表示连接成功 将数据存储至全局
            store.state.account = Account;
            Read_time()

            // console.log('111111', SFI_Mining, SFI_BUSD_Pair_Mining, SFI_BNB_Pair_Mining, BNB_BUSD_Pair_Mining, TPT_BUSD_Pair_Mining, BUSD_USDT_Pair_Mining)
        }

    } else {
        Message.error('未检测到metamask');
        // 提示未检测到metamask
    }
}

// 显示当前用户代币信息 Home
export const user_SFI_info = async () => {
    if (typeof window.ethereum !== 'undefined') {
        try {
            let Token = new ethers.Contract(SFI_Address, SFI.abi, Provider);
            let totalSupplys = await Token.totalSupply()
            console.log("totalSupplys:", +totalSupplys)
            let balance = await Token.balanceOf(Account)
            let decimals = +await Token.decimals()
            // console.log(decimals)
            let burn_amount = await Token.burn_amount()
            //console.log(burn_amount)
            balance = balance / 10 ** decimals
            totalSupplys = totalSupplys / 10 ** decimals
            if (burn_amount != 0) {
                burn_amount = burn_amount / 10 ** decimals
            }

            // 当前用户SFI余额, 用户SFI价值,SFI总价值,SFI锁仓价值,已燃烧数量,SFI流通总量
            //let price, info = await get_price()
            //console.log("busd_price", price, info)
            // return [balance, balance * (+info[0]), totalSupplys * (+info[0]), (+info[1]), burn_amount, totalSupplys]


            return [balance, 0, 0, 0, burn_amount, totalSupplys]
        } catch (err) {
            Message.error("user_SFI_info:", err);
            // 返回失败提示
        }
    }
}

// 参数为矿池合约地址
export const user_SFI_all_info = async (_contract) => {
    if (typeof window.ethereum !== 'undefined') {
        try {
            //let signer = await Provider.getSigner();
            let MiningContracts = new ethers.Contract(_contract, MiningContract.abi, Signer);
            //console.log(666666666666666,Account,await MiningContracts.owner())
            let User_info = await MiningContracts.User_Info(Account)
            //console.log(777777777,User_rewards)
            //let User_LPAmount = await MiningContracts.User_Info(Account)
            let LP_Address = await MiningContracts.LP_Address()
            let LP_contract = new ethers.Contract(LP_Address, Pair.abi, Signer);
            let decimals = +await LP_contract.decimals()
            let lp_amount = +await LP_contract.balanceOf(Account) / 10 ** decimals
            let Sum_lp_amount = +await LP_contract.balanceOf(_contract) / 10 ** decimals
            //console.log(lp_amount)
            // 返回参数为: 当前用户已赚取的SFI, 总年历化, 当前用户已质押的LP总数, 当前矿池质押LP总量价值, LP浏览器地址, 矿池浏览器地址,可质押LP数量
            return [User_info[2], 3, User_info[0], Sum_lp_amount, BSCWEB + LP_Address, BSCWEB + _contract, lp_amount]
        } catch (err) {
            //Message.error("加载矿池信息失败:", err);
            // 返回失败提示
        }
    }

}

// 获取用户USDT余额
export const user_USDT_info = async () => {
    console.log('update-----------------');
    try {
        let Token = new ethers.Contract(USDT_Address, IERC20.abi, Provider)
        let balance = await Token.balanceOf(Account)
        if (balance == 0) return 0
        let decimals = await Token.decimals() + ""
        return balance / (10 ** decimals);
    } catch (err) {
        Message.error("user_USDT_info:", err);
        // 返回失败提示
    }
}

// USDT授权函数
export const USDT_approve = async () => {
    if (typeof window.ethereum !== 'undefined') {
        if (!Signer) return Message.error('授权失败,请先连接钱包');
        try {
            //let signer = await Provider.getSigner();
            let Token = new ethers.Contract(USDT_Address, IERC20.abi, Signer)
            let allowances = await Token.allowance(Account, RushToBuy_Address) + 0
            let balance = await Token.balanceOf(Account) + 0
            console.log(allowances, balance)
            // let decimals = await Token.decimals()
            if (allowances > balance) {
                //console.log(66666666)
                store.state.approved = true;
                Message.success('已授权');
                return true;
                // 直接显示已授权
            } else {
                let totalSupplys = await Token.totalSupply()
                let Tokenapprove = await Token.approve(RushToBuy_Address, totalSupplys)
                await Tokenapprove.wait()
                Message.success('授权成功');
                store.state.approved = true;
                return true;
                // 返回授权成功提示

            }
        } catch (err) {
            Message.error('授权失败');
            return false;
            // 返回授权失败提示
        }
    }
}

// 购买代币函数
export const buy = async (_value) => {
    if (typeof window.ethereum !== 'undefined') {
        try {
            //let signer = await Provider.getSigner();
            let RushToBuyContract = new ethers.Contract(RushToBuy_Address, RushToBuy.abi, Signer);

            if (+await RushToBuyContract.Primary_amount() == 0 && +await RushToBuyContract.Secondar_amount() == 0 && +await RushToBuyContract.Tertiary_amount() == 0) {
                Message.warning('预售已结束');
                // 提示预售已结束,隐藏预售按钮
            }
            //console.log("_value",_value,+await RushToBuyContract.Primary_amount(),+await RushToBuyContract.Game_Player_Amount(Account))

            if (+await RushToBuyContract.Primary_amount() >= +_value) {
                console.log(2);
                MessageBox.confirm(`当前账户剩余可兑换SFI数量为: ${40000 - (+await RushToBuyContract.Game_Player_Amount(Account))}, 当前购买所需要USDT数量为: ${+await RushToBuyContract.PrimaryPrice() * _value / 10e17}`, '提示').then(async () => {
                    let PrimaryBuyTokens = await RushToBuyContract.PrimaryBuyToken(+_value);
                    await PrimaryBuyTokens.wait();
                    store.state.buyStatus = true;
                    Message.success('购买成功');
                })
                // console.log("当前账户剩余可兑换SFI数量为:", +await RushToBuyContract.Game_Player_Amount(Account), "需当前购买所需要USDT数量为: ", +await RushToBuyContract.PrimaryPrice() * _value / 10e18)
            } else {
                if (+await RushToBuyContract.Primary_amount() == 0) {
                    if (+await RushToBuyContract.Secondar_amount() >= +_value) {
                        console.log(999999999999999999999, _value)
                        MessageBox.confirm(`当前账户剩余可兑换SFI数量为: ${40000 - (+await RushToBuyContract.Game_Player_Amount(Account))}, 当前购买所需要USDT数量为: ${+await RushToBuyContract.SecondaryPrice() * _value / 1e18}`, '提示').then(async () => {
                            let SecondarBuyTokens = await RushToBuyContract.SecondarBuyToken(+_value);
                            await SecondarBuyTokens.wait();
                            store.state.buyStatus = true;
                            Message.success('购买成功');
                        })
                        //console.log("当前账户剩余可兑换SFI数量为:",+await RushToBuyContract.Game_Player_Amount(Account),"需当前购买所需要USDT数量为: ",+await RushToBuyContract.Secondar_amount()* _value / 10e18)
                    } else {
                        if (+await RushToBuyContract.Secondar_amount() == 0) {
                            if (+await RushToBuyContract.Tertiary_amount() >= +_value) {
                                MessageBox.confirm(`当前账户剩余可兑换SFI数量为: ${40000 - (+await RushToBuyContract.Game_Player_Amount(Account))}, 当前购买所需要USDT数量为: ${+await RushToBuyContract.TertiaryPrice() * _value / 10e17}`, '提示').then(async () => {
                                    console.log(6666666666666666, _value)
                                    let TertiaryBuyTokens = await RushToBuyContract.TertiaryBuyToken(+_value);
                                    await TertiaryBuyTokens.wait()
                                    store.state.buyStatus = true;
                                    Message.success('购买成功');
                                })
                                //console.log("当前账户剩余可兑换SFI数量为:",+await RushToBuyContract.Game_Player_Amount(Account),"需当前购买所需要USDT数量为: ",+await RushToBuyContract.Tertiary_amount()* _value / 10e18)
                            } else if (+await RushToBuyContract.Tertiary_amount() !== 0) {
                                let surplus = +await RushToBuyContract.Tertiary_amount();
                                return ['三', surplus];
                                // 返回当前第三层价格阶段剩余可卖
                            }
                        } else {
                            let surplus = +await RushToBuyContract.Secondar_amount();
                            return ['二', surplus];
                            // 返回当前第二层价格阶段剩余可卖
                        }
                    }
                } else {
                    let surplus = +await RushToBuyContract.Primary_amount();
                    return ['一', surplus];
                    // 返回当前第一层价格阶段剩余可卖
                }

            }
            console.log(Account);
        } catch (err) {

            //Message.error("USDT_approve", err);
            // 返回err
        }
    }
}

// 读取兑换合约启动的时间戳 获取的时间戳减去当前时间为剩余兑换合约开启时间
export const Read_time = async () => {
    //return 1632744000;
    if (typeof window.ethereum !== 'undefined') {
        try {
            //let signer = await Provider.getSigner();

            let RushToBuyContract = new ethers.Contract(RushToBuy_Address, RushToBuy.abi, Signer)
            console.log(+await RushToBuyContract.start_time())
            return +await RushToBuyContract.start_time();
        } catch (err) {
            Message.error('读取时间失败', err);
            return false;
            // 返回授权失败提示
        }
    }
}

// 获取BUSD和SFI交易对价格以及总TVL
export const get_price = async () => {
    if (typeof window.ethereum !== 'undefined') {
        try {
            //let signer = await Provider.getSigner();
            let SFI_Contract = new ethers.Contract(SFI_Address, SFI.abi, Signer)
            // let SFI_BUSD_PairAddress=await SFI_Contract.SFI_BUSD_PairAddress()
            // let SFI_BNB_PairAddress=await SFI_Contract.SFI_BNB_PairAddress()
            // let BNB_BUSD_PairAddress=await SFI_Contract.BNB_BUSD_PairAddress()
            // let TPT_BUSD_PairAddress=await SFI_Contract.TPT_BUSD_PairAddress()
            // let BUSD_USDT_PairAddress=await SFI_Contract.BUSD_USDT_PairAddress()
            // let SFI_Mining=await SFI_Contract.SFI_Mining()
            //console.log(SFI_BUSD_PairAddress,SFI_BNB_PairAddress,BNB_BUSD_PairAddress,TPT_BUSD_PairAddress,BUSD_USDT_PairAddress,SFI_Mining)
            let SFI_MiningContract = new ethers.Contract(SFI_Mining, MiningContract.abi, Signer)
            let Game_info = await SFI_MiningContract.getGame_info()
            //console.log(55555555,+Game_info[0])
            let SFI_BUSD_pair_Contract = new ethers.Contract(SFI_BUSD_PairAddress, Pair.abi, Signer)
            let Reserves = await SFI_BUSD_pair_Contract.getReserves()
            let price = (+Reserves[0] / +Reserves[1])
            //console.log(6666666666666666,price)
            let SFI_BUSD_LP_amount = +await SFI_BUSD_pair_Contract.totalSupply()

            let SFI_BUSD_LP_value = (+await SFI_BUSD_pair_Contract.balanceOf(SFI_BUSD_Pair_Mining) / SFI_BUSD_LP_amount) * (+await SFI_Contract.balanceOf(SFI_BUSD_PairAddress)) * 2
            if (+await SFI_BUSD_pair_Contract.balanceOf(SFI_BUSD_Pair_Mining) == 0) {
                SFI_BUSD_LP_value = 0
            }
            //console.log("SFI_BUSD_Pair_Mining:",await SFI_Contract.SFI_BUSD_Pair_Mining())
            let SFI_BNB_pair_Contract = new ethers.Contract(SFI_BNB_PairAddress, Pair.abi, Signer)
            let SFI_BNB_LP_amount = +await SFI_BNB_pair_Contract.totalSupply()
            let SFI_BNB_LP_value = (+await SFI_BNB_pair_Contract.balanceOf(SFI_BNB_Pair_Mining) / SFI_BNB_LP_amount) * (+await SFI_Contract.balanceOf(SFI_BNB_PairAddress)) * 2
            if (+await SFI_BNB_pair_Contract.balanceOf(SFI_BNB_Pair_Mining) == 0) {
                SFI_BNB_LP_value = 0
            }
            //console.log("SFI_BNB_LP_value",+await SFI_BNB_pair_Contract.balanceOf(await SFI_Contract.SFI_BNB_Pair_Mining())/SFI_BNB_LP_amount)
            let BNB_BUSD_pair_Contract = new ethers.Contract(BNB_BUSD_PairAddress, Pair.abi, Signer)
            let BNB_BUSD_LP_amount = +await BNB_BUSD_pair_Contract.totalSupply()
            let BNB_BUSD_LP_value = (+await BNB_BUSD_pair_Contract.balanceOf(BNB_BUSD_Pair_Mining) / BNB_BUSD_LP_amount) * (+await SFI_Contract.balanceOf(BNB_BUSD_PairAddress)) * 2
            if (+await BNB_BUSD_pair_Contract.balanceOf(BNB_BUSD_Pair_Mining) == 0) {
                BNB_BUSD_LP_value = 0
            }


            let TPT_BUSD_pair_Contract = new ethers.Contract(TPT_BUSD_PairAddress, Pair.abi, Signer)
            let TPT_BUSD_LP_amount = +await TPT_BUSD_pair_Contract.totalSupply()
            let TPT_BUSD_LP_value = (+await TPT_BUSD_pair_Contract.balanceOf(TPT_BUSD_Pair_Mining) / TPT_BUSD_LP_amount) * (+await SFI_Contract.balanceOf(TPT_BUSD_PairAddress)) * 2
            if (+await TPT_BUSD_pair_Contract.balanceOf(TPT_BUSD_Pair_Mining) == 0) {
                TPT_BUSD_LP_value = 0
            }


            let BUSD_USDT_pair_Contract = new ethers.Contract(BUSD_USDT_PairAddress, Pair.abi, Signer)
            let BUSD_USDT_LP_amount = +await BUSD_USDT_pair_Contract.totalSupply()
            let BUSD_USDT_LP_value = (+await BUSD_USDT_pair_Contract.balanceOf(BUSD_USDT_Pair_Mining) / BUSD_USDT_LP_amount) * (+await SFI_Contract.balanceOf(BUSD_USDT_PairAddress)) * 2
            if (+await BUSD_USDT_pair_Contract.balanceOf(BUSD_USDT_Pair_Mining) == 0) {
                BUSD_USDT_LP_value = 0
            }


            console.log(+Game_info[0], SFI_BUSD_LP_value, SFI_BNB_LP_value, BNB_BUSD_LP_value, TPT_BUSD_LP_value, BUSD_USDT_LP_value)

            let TVL = (+Game_info[0] + SFI_BUSD_LP_value + SFI_BNB_LP_value + BNB_BUSD_LP_value + TPT_BUSD_LP_value + BUSD_USDT_LP_value) * price
            //console.log("price:",+Reserves[0]/+Reserves[1])
            console.log("price:", price, "TVL:", TVL)
            return price, TVL

        } catch (err) {
            //Message.error('获取BUSD和SFI交易对价格失败', err);

            // 返回授权失败提示
        }
    }
}

// 质押
export const pledge = async (contract_addr, amount) => {
    if (typeof window.ethereum !== 'undefined') {
        try {
            //let signer = await Provider.getSigner();
            let mining_Contract = new ethers.Contract(contract_addr, MiningContract.abi, Signer)
            let tx = await mining_Contract.pledge(amount)
            await tx.wait()
            //显示质押成功
            //console.log(contract_addr)
        } catch (err) {
            //Message.error("质押失败:", err);
            Message.error("暂未开放，敬请期待", err);
            // 返回失败提示
        }
    }
}

//提现
export const withdraw = async (contract_addr, amount) => {
    if (typeof window.ethereum !== 'undefined') {
        try {
            //let signer = await Provider.getSigner();
            let mining_Contract = new ethers.Contract(contract_addr, MiningContract.abi, Signer)
            let tx = await mining_Contract.withdraw(amount)
            await tx.wait()
            //显示提现成功
            //console.log(contract_addr)
        } catch (err) {
            //Message.error("质押失败:", err);
            Message.error("暂未开放，敬请期待", err);
            // 返回失败提示
        }
    }
}
//领取收益
export const ReceiveSalary = async (contract_addr) => {
    if (typeof window.ethereum !== 'undefined') {
        try {
            //let signer = await Provider.getSigner();
            //console.log(contract_addr)
            let mining_Contract = new ethers.Contract(contract_addr, MiningContract.abi, Signer)
            let tx = await mining_Contract.ReceiveSalary()
            await tx.wait()
            //显示领取收益成功
            Message.success('领取收益成功');
            //console.log(contract_addr)
        } catch (err) {
            Message.error("暂未开放，敬请期待", err);
            //Message.error("领取收益失败:", err);
            // 返回失败提示
        }
    }
}

//提现数量显示
export const ShowLP = async (contract_addr) => {
    if (typeof window.ethereum !== 'undefined') {
        try {
            return contract_addr
        } catch (err) {
            //Message.error("提现数量显示失败:", err);
            Message.error("暂未开放，敬请期待", err);
            // 返回失败提示
        }
    }
}

//可质押数量显示
export const Show_pledge = async (contract_addr) => {
    if (typeof window.ethereum !== 'undefined') {
        try {
            return contract_addr
        } catch (err) {
            Message.error("暂未开放，敬请期待", err);
            //Message.error("可质押数量显示失败:", err);
            // 返回失败提示
        }
    }
}

// 读取当前价格
export const GetNowPrice = async () => {
    if (typeof window.ethereum !== 'undefined') {
        try {
            //let signer = await Provider.getSigner();
            let RushToBuyContracts = new ethers.Contract(RushToBuy_Address, RushToBuy.abi, Provider)
            if (+await RushToBuyContracts.Primary_amount() != 0) {
                return +await RushToBuyContracts.PrimaryPrice() / 10e17

            } else {
                if (+await RushToBuyContracts.Secondar_amount() != 0) {
                    return +await RushToBuyContracts.SecondaryPrice() / 10e17
                } else {
                    if (+await RushToBuyContracts.Tertiary_amount() != 0) {
                        return +await RushToBuyContracts.TertiaryPrice() / 10e17
                    } else {
                        return 0
                    }
                }
            }

        } catch (err) {
            Message.error('读取价格失败');
            return false;
            // 返回授权失败提示
        }
    }
}

// 获取各个价格
export const GetPrice = async () => {
    if (typeof window.ethereum !== 'undefined') {
        try {
            //let signer = await Provider.getSigner();
            let RushToBuyContracts = new ethers.Contract(RushToBuy_Address, RushToBuy.abi, Provider)
            
            return [+await RushToBuyContracts.PrimaryPrice() / 10e17,+await RushToBuyContracts.SecondaryPrice() / 10e17,+await RushToBuyContracts.TertiaryPrice() / 10e17]
        } catch (err) {
            Message.error('读取价格失败');
            return false;
            // 返回授权失败提示
        }
    }
}
