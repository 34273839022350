<template>
  <div class="game-page position-relative w-100 h-100 color-main text-center">
    <div class="game-wrapper">
      <h3 class="text-center text-plus">{{ $t("game.COMING") }}</h3>
      <div class="text-title mb-3">{{ $t("game.INTRODUCTION") }}</div>
      <div class="game-img">
        <img src="../assets/img/game.jpg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.game-page {
  .game-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    .game-img {
      border-radius: 2rem;
      overflow: hidden;
      img {
        border-radius: 2rem;
        width: 100%;
      }
    }
  }
}
</style>