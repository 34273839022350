<template>
  <div class="home-page">
    <div class="banner-box">
      <img src="../assets/img/home-banner.png" alt="">
    </div>
    <el-row :gutter="32">
      <el-col :xs="24" :sm="12" :md="8">
        <div class="card-box">
          <div class="title-item flex-item align-items-center">
            <div class="title-icon flex-label">
              <Logo />
            </div>
            <div class="text-title font-weight-bold ellipsis-text">{{ $t("home.BALANCE") }}</div>
          </div>
          <div class="main-text ellipsis-text">&#8776; {{ parseInt(info[0] || 0) }} SFI</div>
          <div class="status text-item opacity-0">
            <div class="ellipsis-text">{{ $t("home.USERVALUE") }}</div>
          </div>
          <div class="text-main opacity-0">&#8776; {{ info[1] || 0 }} BUSD</div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8">
        <div class="card-box">
          <div class="title-item flex-item align-items-center">
            <div class="title-icon flex-label">
              <Logo />
            </div>
            <div class="text-title font-weight-bold ellipsis-text">TVL</div>
          </div>
          <div class="main-text ellipsis-text">&#8776; BUSD {{ info[3] || 0 }}</div>
          <div class="status text-item">
            <svg v-for="i in 3" :key="i" t="1628514565549" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2870" width="20" height="20"><path d="M768 332.8l-76.8 76.8c0 0 0-307.2-256-409.6 0 0-25.6 281.6-153.6 384s-384 409.6 128 640c0 0-256-281.6 76.8-486.4 0 0-25.6 102.4 102.4 204.8s0 281.6 0 281.6S1203.2 870.4 768 332.8z" p-id="2871" fill="#d81e06"></path></svg>
            &nbsp;&nbsp;
            <div class="ellipsis-text">{{ $t("home.BURNED") }} SFI</div>
          </div>
          <div class="text-main">&#8776; {{ parseInt(info[4] || 0) }}</div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8">
        <div class="card-box">
          <div class="title-item flex-item align-items-center">
            <div class="title-icon flex-label">
              <Logo />
            </div>
            <div class="text-title font-weight-bold ellipsis-text">{{ $t("home.SUPPLY") }}</div>
          </div>
          <div class="main-text">{{ info[5] || 0 }} SFI</div>
          <div class="status text-item">
            <div class="ellipsis-text">{{ $t("home.VLAUE") }}</div>
          </div>
          <div class="text-main">&#8776; {{ info[6] || 0 }} BUSD</div>
        </div>
      </el-col>
    </el-row>
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { user_SFI_info } from '../methods';

export default {
  name: 'Home',
  data() {
    return {
      info: []
    }
  },
  created() {
    if (this.account) this.getData();
  },
  computed: {
    ...mapGetters(['account'])
  },
  watch: {
    account() {
      this.getData();
    }
  },
  methods: {
    getData() {
      console.log('getData', 1);
      user_SFI_info().then((data) => {
        this.info = data || [];
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .opacity-0 {
    opacity: 0;
  }
  .banner-box {
    text-align: center;
    img {
      width: 100%;
    }
  }
  .el-row {
    margin-top: 2rem;
    padding: 0 15%;
    .el-col {
      margin-bottom: 32px;
      .card-box {
        width: 100%;
        height: 100%;
        padding: 1.5rem;
        box-sizing: border-box;
        border-radius: 20px;
        overflow: hidden;
        background-color: #E7FBFF;
        font-weight: bold;
        .title-item {
          color: #008CD4;
            .title-icon {
              width: 4rem;
              height: 4rem;
              margin-right: 1rem;
              border-radius: 4rem;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #fff;
              img {
                width: 66%;
              }
            }
        }
        .main-text {
          font-size: 1.8rem;
          margin-top: 1.2rem;
          height: 6rem;
        }
        .status {
          display: flex;
          align-items: center;
          margin: 0 0 0.5rem;
        }
      }
    }
  }
</style>
