import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Farm from '../views/Farm.vue'
import Exchange from '../views/Exchange.vue'
import Game from '../views/Game.vue'
import Bridge from '../views/Bridge.vue'
import Market from '../views/Market.vue'
import Paper from '../views/Paper.vue'

Vue.use(VueRouter)

export default new VueRouter({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/farm',
      name: 'Farm',
      component: Farm
    },
    {
      path: '/exchange',
      name: 'Exchange',
      component: Exchange
    },
    {
      path: '/game',
      name: 'Game',
      component: Game
    },
    {
      path: '/bridge',
      name: 'Bridge',
      component: Bridge
    },
    {
      path: '/market',
      name: 'Market',
      component: Market
    },
    {
      path: '/paper',
      name: Paper,
      component: Paper
    }
  ]
})
