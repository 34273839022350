import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/style/index.less';
import i18n from './lang/index';
import Logo from './components/Logo.vue';
import Footer from './components/Footer.vue';

Vue.config.productionTip = false;
Vue.use(Element, { size: 'medium' });
Vue.component('Logo', Logo);
Vue.component('Footer', Footer);

router.beforeEach((to, from, next) => {
  console.log(i18n.locale);
  if(['Bridge', 'Market'].includes(to.name)) {
    let title = '系统消息';
    let message = '敬请期待';
    if (i18n.locale === 'en-US') {
      title = 'System message';
      message = 'coming soon...'
    }
    Vue.prototype.$notify.info({
      title,
      message,
      duration: 2000
    });
    return;
  }
  next();
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
