<template>
  <footer class="text-zero">
    <img class="w-100" src="../assets/img/footer.png" alt="" srcset="">
    <div class="bio-box">
      <div class="logo1">
        <logo />
      </div>
      <div class="logo2">
        <logo />
      </div>
      <div class="logo3">
        <logo />
      </div>
      <div class="crab"></div>
    </div>
  </footer>
</template>

<script>
import Logo from './Logo.vue'

export default {
  components: { Logo },
  data() {
    return {}
  }
}

</script>

<style lang="less" scoped>

@keyframes crab {
  0% {
    background-image: url('../assets/img/crab1.png');
    left: 35%;
  }
  30% {
    background-image: url('../assets/img/crab2.png');
    left: 34.8%;
  }
  100% {
    background-image: url('../assets/img/crab1.png');
    left: 35%;
  }
}

footer {
  position: relative;
  width: 100%;
  .bio-box {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    .logo1 {
      position: absolute;
      bottom: 36%;
      left: 20%;
      .logo-box {
        width: 4rem;
        height: 4rem;
      }
    }
    .logo2 {
      position: absolute;
      bottom: 24%;
      right: 20%;
      .logo-box {
        width: 4rem;
        height: 4rem;
      }
    }
    .logo3 {
      position: absolute;
      bottom: 42%;
      right: 3%;
      .logo-box {
        width: 5.8rem;
        height: 5.8rem;
      }
    }
    .crab {
      position: absolute;
      bottom: 2%;
      width: 94px;
      height: 56px;
      animation: crab 1.2s linear infinite both;
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
}

</style>