<template>
  <div class="market-page w-100 h-100 border-box text-center">
    <div class="w-100 h-100"></div>
  </div>
</template>

<script>

export default {
  name: 'Market',
  data() {
    return {}
  }
}

</script>

<style lang="less" scoped>

.market-page {
  padding: 5%;
  div {
    background-image: url('../assets/img/coming.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

</style>