<template>
  <div class="paper-page w-100 h-100">
    <pdf v-for="i in pageNum" :key="i" :page="i" :src="src" />
  </div>
</template>

<script>
import pdf from 'vue-pdf';

export default {
  data() {
    return {
      src: pdf.createLoadingTask('./file/Starfish.pdf'),
      pageNum: 0
    }
  },
  components: { pdf },
  mounted() {
    this.getPageNum();
  },
  methods: {
    getPageNum() {
      this.src.promise.then(pdf => {
        this.pageNum = pdf.numPages;
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>