<template>
  <div class="farm-page">
    <div class="header-wrapper">
      <div class="header-top flex-item justify-content-around align-items-center">
        <img class="text-img mx-3" src="../assets/img/pool-text.png" alt="">
        <img class="pic-img mx-3" src="../assets/img/pool-banner.png" alt="">
      </div>
      <div>
        <img class="w-100" src="../assets/img/sea.png" alt="">
      </div>
    </div>
    <el-row :gutter="32">
      <el-col :xs="24" :sm="12" :md="8">
        <div class="card-box">
          <div class="text-title font-weight-bold">TPT-BUSD</div>
          <div class="icon-item">
            <img src="../assets/img/icon1.svg" alt="">
            <div></div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="price">&#8776; {{ info1[0] || 0 }}</div>
              <div class="sfi-earned">{{ $t("farm.EARNED") }}</div>
            </div>
            <div>
              <el-button type="warning" size="mini" :loading="benefitLoading[0]" @click="getBenefit(1)">{{ $t("farm.GETBENIFIT") }}</el-button>
            </div>
          </div>
          <div class="mt-3 d-flex justify-content-between align-items-center">
            <div>APR</div>
            <div>&#8776; {{ info1[1] || 0 }}</div>
          </div>
          <div class="stake d-flex justify-content-between align-items-center">
            <div>TPT-BUSD {{ $t("farm.LPSTAKED") }}</div>
            <div>&#8776; {{ info1[2] || 0 }}</div>
          </div>
          <div class="stake d-flex justify-content-between align-items-center">
            <div>TPT-BUSD {{ $t("farm.TAKE") }}</div>
            <div>&#8776; {{ info1[6] || 0 }}</div>
          </div>
          <div class="stake d-flex justify-content-between align-items-center">
            <div>TPT-BUSD {{ $t("farm.CANWITHDRAW") }}</div>
            <div>&#8776; {{ info1[7] || 0 }}</div>
          </div>
          <div class="button2">
            <div class="button-wrapper">
              <logo />
              {{ $t("farm.DETAILS") }}
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="liquidity">{{ $t("farm.LIQUIDITY") }}</div>
            <div>&#8776; BUSD {{ info1[3] || 0 }}</div>
          </div>
          <div class="stake d-flex align-items-center">
            {{ $t("farm.GET") }}TPT-BUSD
            <a class="text-zero" :href="info1[4] || ''">
              <svg t="1628773642645" class="icon ml-3" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2953" width="30" height="30"><path d="M844.3 528.3c-18.8 0-34 15.2-34 34v221.1c0 37.5-30.5 68-68 68H232.1c-37.5 0-68-30.5-68-68V273.2c0-37.5 30.5-68 68-68h221.1c18.8 0 34-15.2 34-34s-15.2-34-34-34H232.1c-75 0-136.1 61-136.1 136.1v510.2c0 75 61 136.1 136.1 136.1h510.2c75 0 136.1-61 136.1-136.1V562.3c0-18.8-15.3-34-34.1-34z" p-id="2954" fill="#1296db"></path><path d="M917.3 277.5L743.6 113.8c-13.7-12.9-35.2-12.2-48.1 1.4-12.9 13.7-12.3 35.2 1.4 48.1l112.9 106.4c-30.1 1.9-60.9 3.5-93 3.5-257.9 0-467.7 179.3-467.7 399.7 0 18.8 15.2 34 34 34s34-15.2 34-34c0-182.9 179.3-331.6 399.7-331.6 33.9 0 66-1.6 97.1-3.6L705.5 452.6c-12.9 13.7-12.3 35.2 1.4 48.1 6.6 6.2 15 9.3 23.3 9.3 9 0 18.1-3.6 24.7-10.7l163.7-173.7c13-13.7 12.4-35.2-1.3-48.1z" p-id="2955" fill="#1296db"></path></svg>
            </a>
          </div>
          <div class="view d-flex align-items-center">
            {{ $t("farm.VIEW") }}
            <a class="text-zero" :href="info1[5] || ''">
              <svg t="1628773642645" class="icon ml-3" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2953" width="30" height="30"><path d="M844.3 528.3c-18.8 0-34 15.2-34 34v221.1c0 37.5-30.5 68-68 68H232.1c-37.5 0-68-30.5-68-68V273.2c0-37.5 30.5-68 68-68h221.1c18.8 0 34-15.2 34-34s-15.2-34-34-34H232.1c-75 0-136.1 61-136.1 136.1v510.2c0 75 61 136.1 136.1 136.1h510.2c75 0 136.1-61 136.1-136.1V562.3c0-18.8-15.3-34-34.1-34z" p-id="2954" fill="#1296db"></path><path d="M917.3 277.5L743.6 113.8c-13.7-12.9-35.2-12.2-48.1 1.4-12.9 13.7-12.3 35.2 1.4 48.1l112.9 106.4c-30.1 1.9-60.9 3.5-93 3.5-257.9 0-467.7 179.3-467.7 399.7 0 18.8 15.2 34 34 34s34-15.2 34-34c0-182.9 179.3-331.6 399.7-331.6 33.9 0 66-1.6 97.1-3.6L705.5 452.6c-12.9 13.7-12.3 35.2 1.4 48.1 6.6 6.2 15 9.3 23.3 9.3 9 0 18.1-3.6 24.7-10.7l163.7-173.7c13-13.7 12.4-35.2-1.3-48.1z" p-id="2955" fill="#1296db"></path></svg>
            </a>
          </div>
          <div class="mt-3 d-flex justify-content-end">
            <el-input placeholder="请输入数量" class="mr-2" v-model="input[0]"></el-input>
            <el-button type="primary" size="mini" @click="pledge(1)" :loading="pledgeLoading[0]">{{ $t("farm.PLEDGE") }}</el-button>
            <el-button type="success" size="mini" @click="withdraw(1)" :loading="withdrawLoading[0]">{{ $t("farm.WITHDRAW") }}</el-button>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8">
        <div class="card-box">
          <div class="text-title font-weight-bold">BNB-BUSD</div>
          <div class="icon-item">
            <img src="../assets/img/icon2.svg" alt="">
            <div></div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="price">&#8776; {{ info2[0] || 0 }}</div>
              <div class="sfi-earned">{{ $t("farm.EARNED") }}</div>
            </div>
            <div>
              <el-button type="warning" size="mini" :loading="benefitLoading[1]" @click="getBenefit(2)">{{ $t("farm.GETBENIFIT") }}</el-button>
            </div>
          </div>
          <div class="mt-3 d-flex justify-content-between align-items-center">
            <div>APR</div>
            <div>&#8776; {{ info2[1] || 0 }}</div>
          </div>
          <div class="stake d-flex justify-content-between align-items-center">
            <div>BNB-BUSD {{ $t("farm.LPSTAKED") }}</div>
            <div>&#8776; {{ info2[2] || 0 }}</div>
          </div>
          <div class="stake d-flex justify-content-between align-items-center">
            <div>BNB-BUSD {{ $t("farm.TAKE") }}</div>
            <div>&#8776; {{ info2[6] || 0 }}</div>
          </div>
          <div class="stake d-flex justify-content-between align-items-center">
            <div>BNB-BUSD {{ $t("farm.CANWITHDRAW") }}</div>
            <div>&#8776; {{ info2[7] || 0 }}</div>
          </div>
          <div class="button2">
            <div class="button-wrapper">
              <logo />
              {{ $t("farm.DETAILS") }}
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="liquidity">{{ $t("farm.LIQUIDITY") }}</div>
            <div>&#8776; BUSD {{ info2[3] || 0 }}</div>
          </div>
          <div class="stake d-flex align-items-center">
            {{ $t("farm.GET") }}BNB-BUSD
            <a class="text-zero" :href="info2[4] || ''">
              <svg t="1628773642645" class="icon ml-3" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2953" width="30" height="30"><path d="M844.3 528.3c-18.8 0-34 15.2-34 34v221.1c0 37.5-30.5 68-68 68H232.1c-37.5 0-68-30.5-68-68V273.2c0-37.5 30.5-68 68-68h221.1c18.8 0 34-15.2 34-34s-15.2-34-34-34H232.1c-75 0-136.1 61-136.1 136.1v510.2c0 75 61 136.1 136.1 136.1h510.2c75 0 136.1-61 136.1-136.1V562.3c0-18.8-15.3-34-34.1-34z" p-id="2954" fill="#1296db"></path><path d="M917.3 277.5L743.6 113.8c-13.7-12.9-35.2-12.2-48.1 1.4-12.9 13.7-12.3 35.2 1.4 48.1l112.9 106.4c-30.1 1.9-60.9 3.5-93 3.5-257.9 0-467.7 179.3-467.7 399.7 0 18.8 15.2 34 34 34s34-15.2 34-34c0-182.9 179.3-331.6 399.7-331.6 33.9 0 66-1.6 97.1-3.6L705.5 452.6c-12.9 13.7-12.3 35.2 1.4 48.1 6.6 6.2 15 9.3 23.3 9.3 9 0 18.1-3.6 24.7-10.7l163.7-173.7c13-13.7 12.4-35.2-1.3-48.1z" p-id="2955" fill="#1296db"></path></svg>
            </a>
          </div>
          <div class="view d-flex align-items-center">
            {{ $t("farm.VIEW") }}
            <a class="text-zero" :href="info2[5] || ''">
              <svg t="1628773642645" class="icon ml-3" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2953" width="30" height="30"><path d="M844.3 528.3c-18.8 0-34 15.2-34 34v221.1c0 37.5-30.5 68-68 68H232.1c-37.5 0-68-30.5-68-68V273.2c0-37.5 30.5-68 68-68h221.1c18.8 0 34-15.2 34-34s-15.2-34-34-34H232.1c-75 0-136.1 61-136.1 136.1v510.2c0 75 61 136.1 136.1 136.1h510.2c75 0 136.1-61 136.1-136.1V562.3c0-18.8-15.3-34-34.1-34z" p-id="2954" fill="#1296db"></path><path d="M917.3 277.5L743.6 113.8c-13.7-12.9-35.2-12.2-48.1 1.4-12.9 13.7-12.3 35.2 1.4 48.1l112.9 106.4c-30.1 1.9-60.9 3.5-93 3.5-257.9 0-467.7 179.3-467.7 399.7 0 18.8 15.2 34 34 34s34-15.2 34-34c0-182.9 179.3-331.6 399.7-331.6 33.9 0 66-1.6 97.1-3.6L705.5 452.6c-12.9 13.7-12.3 35.2 1.4 48.1 6.6 6.2 15 9.3 23.3 9.3 9 0 18.1-3.6 24.7-10.7l163.7-173.7c13-13.7 12.4-35.2-1.3-48.1z" p-id="2955" fill="#1296db"></path></svg>
            </a>
          </div>
          <div class="mt-3 d-flex justify-content-end">
            <el-input placeholder="请输入数量" class="mr-2" v-model="input[1]"></el-input>
            <el-button type="primary" size="mini" @click="pledge(2)" :loading="pledgeLoading[1]">{{ $t("farm.PLEDGE") }}</el-button>
            <el-button type="success" size="mini" @click="withdraw(2)" :loading="withdrawLoading[1]">{{ $t("farm.WITHDRAW") }}</el-button>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8">
        <div class="card-box">
          <div class="text-title font-weight-bold">BUSD-USDT</div>
          <div class="icon-item">
            <img src="../assets/img/icon3.svg" alt="">
            <div></div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="price">&#8776; {{ info3[0] || 0 }}</div>
              <div class="sfi-earned">{{ $t("farm.EARNED") }}</div>
            </div>
            <div>
              <el-button type="warning" size="mini" :loading="benefitLoading[2]" @click="getBenefit(3)">{{ $t("farm.GETBENIFIT") }}</el-button>
            </div>
          </div>
          <div class="mt-3 d-flex justify-content-between align-items-center">
            <div>APR</div>
            <div>&#8776; {{ info3[1] || 0 }}</div>
          </div>
          <div class="stake d-flex justify-content-between align-items-center">
            <div>BUSD-USDT {{ $t("farm.LPSTAKED") }}</div>
            <div>&#8776; {{ info3[2] || 0 }}</div>
          </div>
          <div class="stake d-flex justify-content-between align-items-center">
            <div>BUSD-USDT {{ $t("farm.TAKE") }}</div>
            <div>&#8776; {{ info3[6] || 0 }}</div>
          </div>
          <div class="stake d-flex justify-content-between align-items-center">
            <div>BUSD-USDT {{ $t("farm.CANWITHDRAW") }}</div>
            <div>&#8776; {{ info3[7] || 0 }}</div>
          </div>
          <div class="button2">
            <div class="button-wrapper">
              <logo />
              {{ $t("farm.DETAILS") }}
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="liquidity">{{ $t("farm.LIQUIDITY") }}</div>
            <div>&#8776; BUSD {{ info3[3] || 0 }}</div>
          </div>
          <div class="stake d-flex align-items-center">
            {{ $t("farm.GET") }}BUSD-USDT
            <a class="text-zero" :href="info3[4] || ''">
              <svg t="1628773642645" class="icon ml-3" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2953" width="30" height="30"><path d="M844.3 528.3c-18.8 0-34 15.2-34 34v221.1c0 37.5-30.5 68-68 68H232.1c-37.5 0-68-30.5-68-68V273.2c0-37.5 30.5-68 68-68h221.1c18.8 0 34-15.2 34-34s-15.2-34-34-34H232.1c-75 0-136.1 61-136.1 136.1v510.2c0 75 61 136.1 136.1 136.1h510.2c75 0 136.1-61 136.1-136.1V562.3c0-18.8-15.3-34-34.1-34z" p-id="2954" fill="#1296db"></path><path d="M917.3 277.5L743.6 113.8c-13.7-12.9-35.2-12.2-48.1 1.4-12.9 13.7-12.3 35.2 1.4 48.1l112.9 106.4c-30.1 1.9-60.9 3.5-93 3.5-257.9 0-467.7 179.3-467.7 399.7 0 18.8 15.2 34 34 34s34-15.2 34-34c0-182.9 179.3-331.6 399.7-331.6 33.9 0 66-1.6 97.1-3.6L705.5 452.6c-12.9 13.7-12.3 35.2 1.4 48.1 6.6 6.2 15 9.3 23.3 9.3 9 0 18.1-3.6 24.7-10.7l163.7-173.7c13-13.7 12.4-35.2-1.3-48.1z" p-id="2955" fill="#1296db"></path></svg>
            </a>
          </div>
          <div class="view d-flex align-items-center">
            {{ $t("farm.VIEW") }}
            <a class="text-zero" :href="info3[5] || ''">
              <svg t="1628773642645" class="icon ml-3" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2953" width="30" height="30"><path d="M844.3 528.3c-18.8 0-34 15.2-34 34v221.1c0 37.5-30.5 68-68 68H232.1c-37.5 0-68-30.5-68-68V273.2c0-37.5 30.5-68 68-68h221.1c18.8 0 34-15.2 34-34s-15.2-34-34-34H232.1c-75 0-136.1 61-136.1 136.1v510.2c0 75 61 136.1 136.1 136.1h510.2c75 0 136.1-61 136.1-136.1V562.3c0-18.8-15.3-34-34.1-34z" p-id="2954" fill="#1296db"></path><path d="M917.3 277.5L743.6 113.8c-13.7-12.9-35.2-12.2-48.1 1.4-12.9 13.7-12.3 35.2 1.4 48.1l112.9 106.4c-30.1 1.9-60.9 3.5-93 3.5-257.9 0-467.7 179.3-467.7 399.7 0 18.8 15.2 34 34 34s34-15.2 34-34c0-182.9 179.3-331.6 399.7-331.6 33.9 0 66-1.6 97.1-3.6L705.5 452.6c-12.9 13.7-12.3 35.2 1.4 48.1 6.6 6.2 15 9.3 23.3 9.3 9 0 18.1-3.6 24.7-10.7l163.7-173.7c13-13.7 12.4-35.2-1.3-48.1z" p-id="2955" fill="#1296db"></path></svg>
            </a>
          </div>
          <div class="mt-3 d-flex justify-content-end">
            <el-input placeholder="请输入数量" class="mr-2" v-model="input[2]"></el-input>
            <el-button type="primary" size="mini" @click="pledge(3)">{{ $t("farm.PLEDGE") }}</el-button>
            <el-button type="success" size="mini" @click="withdraw(3)">{{ $t("farm.WITHDRAW") }}</el-button>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8">
        <div class="card-box">
          <div class="text-title font-weight-bold">SFI-BUSD</div>
          <div class="icon-item">
            <img src="../assets/img/logo.png" alt="">
            <div></div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="price">&#8776; {{ info4[0] || 0 }}</div>
              <div class="sfi-earned">{{ $t("farm.EARNED") }}</div>
            </div>
            <div>
              <el-button type="warning" size="mini" :loading="benefitLoading[3]" @click="getBenefit(4)">{{ $t("farm.GETBENIFIT") }}</el-button>
            </div>
          </div>
          <div class="mt-3 d-flex justify-content-between align-items-center">
            <div>APR</div>
            <div>&#8776; {{ info4[1] || 0 }}</div>
          </div>
          <div class="stake d-flex justify-content-between justify-content-between align-items-center">
            <div>SFI-BUSD {{ $t("farm.LPSTAKED") }}</div>
            <div>&#8776; {{ info4[2] || 0 }}</div>
          </div>
          <div class="stake d-flex justify-content-between align-items-center">
            <div>SFI-BUSD {{ $t("farm.TAKE") }}</div>
            <div>&#8776; {{ info4[6] || 0 }}</div>
          </div>
          <div class="stake d-flex justify-content-between align-items-center">
            <div>SFI-BUSD {{ $t("farm.CANWITHDRAW") }}</div>
            <div>&#8776; {{ info4[7] || 0 }}</div>
          </div>
          <div class="button2">
            <div class="button-wrapper">
              <logo />
              {{ $t("farm.DETAILS") }}
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="liquidity">{{ $t("farm.LIQUIDITY") }}</div>
            <div>&#8776; BUSD {{ info4[3] || 0 }}</div>
          </div>
          <div class="stake d-flex align-items-center">
            {{ $t("farm.GET") }}SFI-BUSD
            <a class="text-zero" :href="info4[4] || ''">
              <svg t="1628773642645" class="icon ml-3" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2953" width="30" height="30"><path d="M844.3 528.3c-18.8 0-34 15.2-34 34v221.1c0 37.5-30.5 68-68 68H232.1c-37.5 0-68-30.5-68-68V273.2c0-37.5 30.5-68 68-68h221.1c18.8 0 34-15.2 34-34s-15.2-34-34-34H232.1c-75 0-136.1 61-136.1 136.1v510.2c0 75 61 136.1 136.1 136.1h510.2c75 0 136.1-61 136.1-136.1V562.3c0-18.8-15.3-34-34.1-34z" p-id="2954" fill="#1296db"></path><path d="M917.3 277.5L743.6 113.8c-13.7-12.9-35.2-12.2-48.1 1.4-12.9 13.7-12.3 35.2 1.4 48.1l112.9 106.4c-30.1 1.9-60.9 3.5-93 3.5-257.9 0-467.7 179.3-467.7 399.7 0 18.8 15.2 34 34 34s34-15.2 34-34c0-182.9 179.3-331.6 399.7-331.6 33.9 0 66-1.6 97.1-3.6L705.5 452.6c-12.9 13.7-12.3 35.2 1.4 48.1 6.6 6.2 15 9.3 23.3 9.3 9 0 18.1-3.6 24.7-10.7l163.7-173.7c13-13.7 12.4-35.2-1.3-48.1z" p-id="2955" fill="#1296db"></path></svg>
            </a>
          </div>
          <div class="view d-flex align-items-center">
            {{ $t("farm.VIEW") }}
            <a class="text-zero" :href="info4[5] || ''">
              <svg t="1628773642645" class="icon ml-3" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2953" width="30" height="30"><path d="M844.3 528.3c-18.8 0-34 15.2-34 34v221.1c0 37.5-30.5 68-68 68H232.1c-37.5 0-68-30.5-68-68V273.2c0-37.5 30.5-68 68-68h221.1c18.8 0 34-15.2 34-34s-15.2-34-34-34H232.1c-75 0-136.1 61-136.1 136.1v510.2c0 75 61 136.1 136.1 136.1h510.2c75 0 136.1-61 136.1-136.1V562.3c0-18.8-15.3-34-34.1-34z" p-id="2954" fill="#1296db"></path><path d="M917.3 277.5L743.6 113.8c-13.7-12.9-35.2-12.2-48.1 1.4-12.9 13.7-12.3 35.2 1.4 48.1l112.9 106.4c-30.1 1.9-60.9 3.5-93 3.5-257.9 0-467.7 179.3-467.7 399.7 0 18.8 15.2 34 34 34s34-15.2 34-34c0-182.9 179.3-331.6 399.7-331.6 33.9 0 66-1.6 97.1-3.6L705.5 452.6c-12.9 13.7-12.3 35.2 1.4 48.1 6.6 6.2 15 9.3 23.3 9.3 9 0 18.1-3.6 24.7-10.7l163.7-173.7c13-13.7 12.4-35.2-1.3-48.1z" p-id="2955" fill="#1296db"></path></svg>
            </a>
          </div>
          <div class="mt-3 d-flex justify-content-end">
            <el-input placeholder="请输入数量" class="mr-2" v-model="input[3]"></el-input>
            <el-button type="primary" size="mini" @click="pledge(4)" :loading="pledgeLoading[3]">{{ $t("farm.PLEDGE") }}</el-button>
            <el-button type="success" size="mini" @click="withdraw(4)" :loading="withdrawLoading[3]">{{ $t("farm.WITHDRAW") }}</el-button>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8">
        <div class="card-box">
          <div class="text-title font-weight-bold">SFI-BNB</div>
          <div class="icon-item">
            <img src="../assets/img/logo.png" alt="">
            <div></div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="price">&#8776; {{ info5[0] || 0 }}</div>
              <div class="sfi-earned">{{ $t("farm.EARNED") }}</div>
            </div>
            <div>
              <el-button type="warning" size="mini" :loading="benefitLoading[4]" @click="getBenefit(5)">{{ $t("farm.GETBENIFIT") }}</el-button>
            </div>
          </div>
          <div class="mt-3 d-flex justify-content-between align-items-center">
            <div>APR</div>
            <div>&#8776; {{ info5[1] || 0 }}</div>
          </div>
          <div class="stake d-flex justify-content-between justify-content-between align-items-center">
            <div>SFI-BNB {{ $t("farm.LPSTAKED") }}</div>
            <div>&#8776; {{ info5[2] || 0 }}</div>
          </div>
          <div class="stake d-flex justify-content-between align-items-center">
            <div>SFI-BNB {{ $t("farm.TAKE") }}</div>
            <div>&#8776; {{ info5[6] || 0 }}</div>
          </div>
          <div class="stake d-flex justify-content-between align-items-center">
            <div>SFI-BNB {{ $t("farm.CANWITHDRAW") }}</div>
            <div>&#8776; {{ info5[7] || 0 }}</div>
          </div>
          <div class="button2">
            <div class="button-wrapper">
              <logo />
              {{ $t("farm.DETAILS") }}
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="liquidity">{{ $t("farm.LIQUIDITY") }}</div>
            <div>&#8776; BUSD {{ info5[3] || 0 }}</div>
          </div>
          <div class="stake d-flex align-items-center">
            {{ $t("farm.GET") }}SFI-BNB
            <a class="text-zero" :href="info5[4] || ''">
              <svg t="1628773642645" class="icon ml-3" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2953" width="30" height="30"><path d="M844.3 528.3c-18.8 0-34 15.2-34 34v221.1c0 37.5-30.5 68-68 68H232.1c-37.5 0-68-30.5-68-68V273.2c0-37.5 30.5-68 68-68h221.1c18.8 0 34-15.2 34-34s-15.2-34-34-34H232.1c-75 0-136.1 61-136.1 136.1v510.2c0 75 61 136.1 136.1 136.1h510.2c75 0 136.1-61 136.1-136.1V562.3c0-18.8-15.3-34-34.1-34z" p-id="2954" fill="#1296db"></path><path d="M917.3 277.5L743.6 113.8c-13.7-12.9-35.2-12.2-48.1 1.4-12.9 13.7-12.3 35.2 1.4 48.1l112.9 106.4c-30.1 1.9-60.9 3.5-93 3.5-257.9 0-467.7 179.3-467.7 399.7 0 18.8 15.2 34 34 34s34-15.2 34-34c0-182.9 179.3-331.6 399.7-331.6 33.9 0 66-1.6 97.1-3.6L705.5 452.6c-12.9 13.7-12.3 35.2 1.4 48.1 6.6 6.2 15 9.3 23.3 9.3 9 0 18.1-3.6 24.7-10.7l163.7-173.7c13-13.7 12.4-35.2-1.3-48.1z" p-id="2955" fill="#1296db"></path></svg>
            </a>
          </div>
          <div class="view d-flex align-items-center">
            {{ $t("farm.VIEW") }}
            <a class="text-zero" :href="info5[5] || ''">
              <svg t="1628773642645" class="icon ml-3" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2953" width="30" height="30"><path d="M844.3 528.3c-18.8 0-34 15.2-34 34v221.1c0 37.5-30.5 68-68 68H232.1c-37.5 0-68-30.5-68-68V273.2c0-37.5 30.5-68 68-68h221.1c18.8 0 34-15.2 34-34s-15.2-34-34-34H232.1c-75 0-136.1 61-136.1 136.1v510.2c0 75 61 136.1 136.1 136.1h510.2c75 0 136.1-61 136.1-136.1V562.3c0-18.8-15.3-34-34.1-34z" p-id="2954" fill="#1296db"></path><path d="M917.3 277.5L743.6 113.8c-13.7-12.9-35.2-12.2-48.1 1.4-12.9 13.7-12.3 35.2 1.4 48.1l112.9 106.4c-30.1 1.9-60.9 3.5-93 3.5-257.9 0-467.7 179.3-467.7 399.7 0 18.8 15.2 34 34 34s34-15.2 34-34c0-182.9 179.3-331.6 399.7-331.6 33.9 0 66-1.6 97.1-3.6L705.5 452.6c-12.9 13.7-12.3 35.2 1.4 48.1 6.6 6.2 15 9.3 23.3 9.3 9 0 18.1-3.6 24.7-10.7l163.7-173.7c13-13.7 12.4-35.2-1.3-48.1z" p-id="2955" fill="#1296db"></path></svg>
            </a>
          </div>
          <div class="mt-3 d-flex justify-content-end">
            <el-input placeholder="请输入数量" class="mr-2" v-model="input[4]"></el-input>
            <el-button type="primary" size="mini" @click="pledge(5)" :loading="pledgeLoading[4]">{{ $t("farm.PLEDGE") }}</el-button>
            <el-button type="success" size="mini" @click="withdraw(5)" :loading="withdrawLoading[4]">{{ $t("farm.WITHDRAW") }}</el-button>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="12" :md="8">
        <div class="card-box">
          <div class="text-title font-weight-bold">SFI</div>
          <div class="icon-item">
            <img src="../assets/img/logo.png" alt="">
            <div></div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="price">&#8776; {{ info6[0] || 0 }}</div>
              <div class="sfi-earned">{{ $t("farm.EARNED") }}</div>
            </div>
            <div>
              <el-button type="warning" size="mini" :loading="benefitLoading[5]" @click="getBenefit(6)">{{ $t("farm.GETBENIFIT") }}</el-button>
            </div>
          </div>
          <div class="mt-3 d-flex justify-content-between align-items-center">
            <div>APR</div>
            <div>&#8776; {{ info6[1] || 0 }}</div>
          </div>
          <div class="stake d-flex justify-content-between justify-content-between align-items-center">
            <div>SFI {{ $t("farm.LPSTAKED") }}</div>
            <div>&#8776; {{ info6[2] || 0 }}</div>
          </div>
          <div class="stake d-flex justify-content-between align-items-center">
            <div>SFI {{ $t("farm.TAKE") }}</div>
            <div>&#8776; {{ info6[6] || 0 }}</div>
          </div>
          <div class="stake d-flex justify-content-between align-items-center">
            <div>SFI {{ $t("farm.CANWITHDRAW") }}</div>
            <div>&#8776; {{ info6[7] || 0 }}</div>
          </div>
          <div class="button2">
            <div class="button-wrapper">
              <logo />
              {{ $t("farm.DETAILS") }}
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="liquidity">{{ $t("farm.LIQUIDITY") }}</div>
            <div>&#8776; BUSD {{ info6[3] || 0 }}</div>
          </div>
          <div class="stake d-flex align-items-center">
            {{ $t("farm.GET") }}SFI
            <a class="text-zero" :href="info6[4] || ''">
              <svg t="1628773642645" class="icon ml-3" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2953" width="30" height="30"><path d="M844.3 528.3c-18.8 0-34 15.2-34 34v221.1c0 37.5-30.5 68-68 68H232.1c-37.5 0-68-30.5-68-68V273.2c0-37.5 30.5-68 68-68h221.1c18.8 0 34-15.2 34-34s-15.2-34-34-34H232.1c-75 0-136.1 61-136.1 136.1v510.2c0 75 61 136.1 136.1 136.1h510.2c75 0 136.1-61 136.1-136.1V562.3c0-18.8-15.3-34-34.1-34z" p-id="2954" fill="#1296db"></path><path d="M917.3 277.5L743.6 113.8c-13.7-12.9-35.2-12.2-48.1 1.4-12.9 13.7-12.3 35.2 1.4 48.1l112.9 106.4c-30.1 1.9-60.9 3.5-93 3.5-257.9 0-467.7 179.3-467.7 399.7 0 18.8 15.2 34 34 34s34-15.2 34-34c0-182.9 179.3-331.6 399.7-331.6 33.9 0 66-1.6 97.1-3.6L705.5 452.6c-12.9 13.7-12.3 35.2 1.4 48.1 6.6 6.2 15 9.3 23.3 9.3 9 0 18.1-3.6 24.7-10.7l163.7-173.7c13-13.7 12.4-35.2-1.3-48.1z" p-id="2955" fill="#1296db"></path></svg>
            </a>
          </div>
          <div class="view d-flex align-items-center">
            {{ $t("farm.VIEW") }}
            <a class="text-zero" :href="info6[5] || ''">
              <svg t="1628773642645" class="icon ml-3" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2953" width="30" height="30"><path d="M844.3 528.3c-18.8 0-34 15.2-34 34v221.1c0 37.5-30.5 68-68 68H232.1c-37.5 0-68-30.5-68-68V273.2c0-37.5 30.5-68 68-68h221.1c18.8 0 34-15.2 34-34s-15.2-34-34-34H232.1c-75 0-136.1 61-136.1 136.1v510.2c0 75 61 136.1 136.1 136.1h510.2c75 0 136.1-61 136.1-136.1V562.3c0-18.8-15.3-34-34.1-34z" p-id="2954" fill="#1296db"></path><path d="M917.3 277.5L743.6 113.8c-13.7-12.9-35.2-12.2-48.1 1.4-12.9 13.7-12.3 35.2 1.4 48.1l112.9 106.4c-30.1 1.9-60.9 3.5-93 3.5-257.9 0-467.7 179.3-467.7 399.7 0 18.8 15.2 34 34 34s34-15.2 34-34c0-182.9 179.3-331.6 399.7-331.6 33.9 0 66-1.6 97.1-3.6L705.5 452.6c-12.9 13.7-12.3 35.2 1.4 48.1 6.6 6.2 15 9.3 23.3 9.3 9 0 18.1-3.6 24.7-10.7l163.7-173.7c13-13.7 12.4-35.2-1.3-48.1z" p-id="2955" fill="#1296db"></path></svg>
            </a>
          </div>
          <div class="mt-3 d-flex justify-content-end">
            <el-input placeholder="请输入数量" class="mr-2" v-model="input[5]"></el-input>
            <el-button type="primary" size="mini" @click="pledge(6)" :loading="pledgeLoading[5]">{{ $t("farm.PLEDGE") }}</el-button>
            <el-button type="success" size="mini" @click="withdraw(6)" :loading="withdrawLoading[5]">{{ $t("farm.WITHDRAW") }}</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SFI_Mining, SFI_BUSD_Pair_Mining, SFI_BNB_Pair_Mining, BNB_BUSD_Pair_Mining, TPT_BUSD_Pair_Mining, BUSD_USDT_Pair_Mining, user_SFI_all_info, ReceiveSalary, pledge, withdraw } from '../methods';

export default {
  name: 'Pools',
  data() {
    return {
      benefitLoading: [false, false, false, false, false, false],
      pledgeLoading: [false, false, false, false, false, false],
      withdrawLoading: [false, false, false, false, false, false],
      input: ['', '', '', '', '', ''],
      info1: [],
      info2: [],
      info3: [],
      info4: [],
      info5: [],
      info6: []
    }
  },
  created() {
    if (this.account) this.getData();
  },
  computed: {
    ...mapGetters(['account'])
  },
  watch: {
    account() {
      this.getData();
    }
  },
  methods: {
    getData() {
      //console.log('getData', SFI_Mining, SFI_BUSD_Pair_Mining, SFI_BNB_Pair_Mining, BNB_BUSD_Pair_Mining, TPT_BUSD_Pair_Mining, BUSD_USDT_Pair_Mining);
      // 此处为参数
      //"0x5f21D143A70712FF770fE71Fbf7f15E750162A30", "0x410FAf26Ab93b14076cFC69388613609B3ea59a2", "0x49911C7e17C8477e78f94b64b8C6c4749efcF737", "0x98E83F6BBA99dB8bdbb99200f3a91e369bB4962C", "0x108a024c07BD3C5B26fdC2805346847E16fd5F6c", "0x989F3Ac356b129Bd41F75FC3bCa5939f2A33DAa"
      [SFI_Mining, SFI_BUSD_Pair_Mining, SFI_BNB_Pair_Mining, BNB_BUSD_Pair_Mining, TPT_BUSD_Pair_Mining, BUSD_USDT_Pair_Mining].forEach((item, index) => {
        user_SFI_all_info(item).then((data) => {
          console.log(data, '--');
          this[`info${index + 1}`] = data || [];
        })
      })
    },
    returnAddr(index) {
      let addr = '';
      switch(index) {
        case 1: 
          addr = SFI_Mining;
          break;
        case 2:
          addr = SFI_BUSD_Pair_Mining;
          break;
        case 3:
          addr = SFI_BNB_Pair_Mining;
          break;
        case 4: 
          addr = BNB_BUSD_Pair_Mining;
          break;
        case 5:
          addr = TPT_BUSD_Pair_Mining;
          break;
        case 6:
          addr = BUSD_USDT_Pair_Mining;
          break;
        default:
          addr = '';
          break;
      }
      return addr;
    },
    getBenefit(index) {
      this.benefitLoading[index - 1] = true;
      const addr = this.returnAddr(index);
      ReceiveSalary(addr).finally(() => {
        this.benefitLoading[index - 1] = false;
      })
    },
    pledge(index) {
      console.log(this.input);
      this.pledgeLoading[index - 1] = true;
      const addr = this.returnAddr(index);
      pledge(addr, this.input[index - 1]).finally(() => {
        this.pledgeLoading[index - 1] = false;
      })
    },
    withdraw(index) {
      this.withdrawLoading[index - 1] = true;
      const addr = this.returnAddr(index);
      withdraw(addr, this.input[index - 1]).finally(() => {
        this.withdrawLoading[index - 1] = false;
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .header-wrapper {
    margin-bottom: 2rem;
    .header-top {
      width: 100%;
      height: 20rem;
      padding: 0 2rem;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .text-img {
        height: 40%;
      }
      .pic-img {
        height: 90%;
      }
    }
  }
  .el-row {
    margin-top: -12%;
    padding: 0 15%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .el-col {
      margin-bottom: 2rem;
      // min-width: 20rem;
      .card-box {
        width: 100%;
        height: 100%;
        padding: 1.5rem;
        box-sizing: border-box;
        border-radius: 1.5rem;
        overflow: hidden;
        background-color: #E7FBFF;
        font-weight: bold;
        .text-title {
          color: #FFA800;
        }
        .icon-item {
          width: 5rem;
          height: 5rem;
          margin-top: 1.2rem;
          border-radius: 5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #008CD4;
          position: relative;
          img {
            width: 80%;
          }
          div {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 2rem;
            height: 2rem;
            border-radius: 2rem;
            border: 0.1rem solid #fff;
            background-color: #F0B90B;
            background-image: url('../assets/img/bian.png');
            background-size: 80%;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
        .price {
          font-size: 2.2rem;
          margin: 0.6rem 0 0.2rem;
        }
        .button1 {
          width: 100%;
          height: 3rem;
          line-height: 3rem;
          border-radius: 3rem;
          margin: 0.8rem 0;
          text-align: center;
          background-color: #008CD4;
          color: #fff;
        }
        .button2 {
          display: flex;
          margin: 1.6rem 0;
          .button-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0.2rem solid #008CD4;
            height: 2rem;
            line-height: 2rem;
            border-radius: 2rem;
            padding: 0 0.6rem;
            color: #008CD4;
            .logo-box {
              width: 1.5rem;
              height: 1.5rem;
              margin-right: 0.5rem;
            }
          }
        }
        .liquidity {
          color: #aaa;
        }
        .stake {
          margin-top: 0.3rem;
          svg {
            width: 1.8rem;
            height: 1.8rem;
          }
        }
        .view {
          margin-top: 0.4rem;
          svg {
            width: 1.8rem;
            height: 1.8rem;
          }
        }
      }
      &:nth-of-type(6) {
        .card-box {
          .icon-item {
            div {
              background-color: #008CD4;
              background-image: url('../assets/img/logo.png');
            }
          }
        }
      }
    }
  }
</style>
