<template>
  <div class="exchange-page">
    <img class="w-100" src="../assets/img/leaf.png" alt="">
    <div class="rule-wrapper">
      <div class="rule-box">
        <div class="time">
          <span v-if="end">{{ $t("exchange.END") }}</span>
          <div v-else-if="start" class="start-text d-flex flex-column align-items-center justify-content-center">
            <div>{{ $t("exchange.BEGIN") }}</div>
            <div>{{ day }} <span class="name">{{ $t("exchange.DAYS") }}</span> {{ hour }} <span class="name">{{ $t("exchange.HOURS") }}</span> {{ minute }} <span class="name">{{ $t("exchange.MINUTES") }}</span> {{ second }} <span class="name">{{ $t("exchange.SECONDES") }}</span></div>
          </div>
          <template v-else>
            <span>{{ day }} <span class="name">{{ $t("exchange.DAYS") }}</span> {{ hour }} <span class="name">{{ $t("exchange.HOURS") }}</span> {{ minute }} <span class="name">{{ $t("exchange.MINUTES") }}</span> {{ second }} <span class="name">{{ $t("exchange.SECONDES") }}</span></span>
          </template>
        </div>
        <div class="d-flex align-items-center rule-title">
          <svg
            t="1628315940407"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="10106"
            width="50"
            height="50"
          >
            <path
              d="M327.567 451.597V343.272h397.854c42.136 0 78.704 23.823 96.986 58.732 13.734-19.462 21.807-43.206 21.807-68.838 0-66.012-53.512-119.526-119.526-119.526h-397.12V104.886c0-16.012-18.056-25.367-31.136-16.133L50.85 262.108c-11.146 7.868-11.146 24.398 0 32.266L296.432 467.73c13.081 9.233 31.135-0.123 31.135-16.133zM972.16 729.645L726.577 556.29c-13.08-9.235-31.135 0.12-31.135 16.132V680.75H297.59c-42.138 0-78.706-23.823-96.987-58.732-13.735 19.462-21.807 43.206-21.807 68.836 0 66.014 53.513 119.527 119.525 119.527h397.121v108.754c0 16.011 18.056 25.366 31.135 16.132L972.16 761.912c11.145-7.87 11.145-24.4 0-32.267z"
              p-id="10107"
              fill="#0080BF"
            ></path>
          </svg>
          <div>{{ $t("exchange.RULES") }}</div>
        </div>
        <div class="rule-content">
          <div>{{ $t("exchange.LINE1") }}</div>
          <div>{{ $t("exchange.LINE2") }}</div>
          <div>{{ $t("exchange.LINE3") }}</div>
          <div>{{ $t("exchange.LINE4") }}</div>
        </div>
        <div class="rule-layer">
          <div class="layer-row">
            <div class="layer-index">1</div>
            <div class="layer-title">{{ $t("exchange.LAYER1") }}</div>
            <div class="layer-text">{{ $t("exchange.PRICE1") }}<span class="font-weight-bold">{{ levelData[0] || '--'}}U</span></div>
          </div>
          <div class="layer-row">
            <div class="layer-index">2</div>
            <div class="layer-title">{{ $t("exchange.LAYER2") }}</div>
            <div class="layer-text">{{ $t("exchange.PRICE2") }}<span class="font-weight-bold">{{ levelData[1] || '--'}}U</span></div>
          </div>
          <div class="layer-row">
            <div class="layer-index">3</div>
            <div class="layer-title">{{ $t("exchange.LAYER3") }}</div>
            <div class="layer-text">{{ $t("exchange.PRICE3") }}<span class="font-weight-bold">{{ levelData[2] || '--' }}U</span></div>
          </div>
        </div>
      </div>
      <div class="exchange-box">
        <div class="exchange-title">{{ $t("exchange.EXCHANGE") }}</div>
        <div class="exchange-content">
          <div>{{ $t("exchange.BALANCE") }} &#8776; {{ (balance || 0).toFixed(3) }}</div>
          <div class="exchange-input">
            <el-input :placeholder="$t('exchange.SINGLE')" v-model="inputVal">
               <template slot="append">
                 <div class="d-flex align-items-center">
                   <span>X2000</span>
                   <i class="el-icon-question text-plus color-main ml-2"></i>
                 </div>
               </template>
            </el-input>
          </div>
          <div class="exchange-info">
            <div>{{ $t("exchange.EXPLAIN") }}</div>
            <div>{{ $t("exchange.CURRENT1") }} {{ currentPrice }}U<span v-if="levelData[2] !== currentPrice">, {{ $t("exchange.CURRENT2") }}{{ levelData[2] || '--' }}U</span></div>
          </div>
          <div class="exchange-button">
            <template v-if="approved">
              <el-button :loading="buyLoading" @click="onBuy">{{ $t("exchange.BUY") }}</el-button>
            </template>
            <template v-else>
              <el-button :loading="approveLoading" @click="onApprive">{{ $t("exchange.APPROVAL")  }}</el-button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <img class="w-100" src="../assets/img/sea2.png" alt="">
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { user_USDT_info, USDT_approve, buy, Read_time, GetPrice, GetNowPrice } from '../methods';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export default {
  data() {
    return {
      approveLoading: false,
      buyLoading: false,
      levelData: [],
      balance: 0,
      info: [],
      inputVal: '',
      initTime: '',
      timer: '',
      day: '-',
      hour: '-',
      minute: '-',
      second: '-',
      start: false,
      end: false,
      currentPrice: '--'
    }
  },
  created() {
    if (this.account) {
      this.getData();
      this.getPrice();
      this.getNowPrice();
      this.getTime();
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = '';
  },
  computed: {
    ...mapGetters(['account', 'approved', 'returnBuyStatus'])
  },
  watch: {
    account() {
      this.getData();
      this.getPrice();
      this.getNowPrice();
      this.getTime();
    },
    connWallet(val) {
      console.log('connWallet', val);
    },
    returnBuyStatus(val) {
      console.log(val, '=======');
      if (val) this.getData();
      this.updateBuy(false);
    }
  },
  methods: {
    ...mapMutations(['updateBuy']),
    getData() {
      console.log('getData', 3);
      user_USDT_info().then((data) => {
        this.balance = data || 0;
      })
    },
    getPrice() {
      GetPrice().then((data) => {
        if (data) this.levelData = data;
      })
    },
    getNowPrice() {
      GetNowPrice().then((data) => {
        if (!data) this.end = true;
        else this.currentPrice = data;
      })
    },
    getTime() {
      Read_time().then((data) => {
        if (data) {
          this.initTime = data * 1000;
          // this.initTime = 1630942828245
          this.setTimer();
        }
      })
    },
    setTimer() {
      this.timer = setInterval(() => {
        const nowTime = new Date().getTime();
        const { initTime } = this;
        const diffTime = initTime - nowTime; 

        const duration = dayjs.duration(diffTime);
        const day = duration.days();
        const hour = duration.hours();
        const minute = duration.minutes();
        const second = duration.seconds();
        if (diffTime <= 0) this.start = true;
        this.day = Math.abs(day);
        this.hour = Math.abs(hour);
        this.minute = Math.abs(minute);
        this.second = Math.abs(second);
      }, 1000)
    },
    onApprive() {
      const inputVal = +this.inputVal;
      if (!inputVal || inputVal <= 0 || inputVal > 20) return this.$message.error('请输入0-20之间的数字');
      this.approveLoading = true;
      USDT_approve().then(() => {
        this.approveLoading = false;
      })
    },
    onBuy() {
      const inputVal = +this.inputVal;
      if (!inputVal || inputVal <= 0 || inputVal > 20) return this.$message.error('请输入0-20之间的数字');
          this.buyLoading = true;
          buy(this.inputVal*2000).then((data) => {
            this.buyLoading = false;
            console.log(data, this.approved, 'approved');
            if (data && data.length === 2) {
              this.$alert(`当前第${data[0]}层价格阶段剩余可卖${data[1]}`, '提示', {
                confirmButtonText: '关闭',
                callback: () => {
                  this.$message({
                    type: 'info',
                    message: `当前第${data[0]}层价格阶段剩余可卖${data[1]}`
                  });
                }
              });
            }
          });
    }
  }
}

</script>

<style lang="less" scoped>

.exchange-page {
  position: relative;
  width: 100%;
  height: 100%;
  color: #0080BF;
  .rule-wrapper {
    width: 100%;
    padding: 10% 5% 2rem 5%;
    margin-top: -25%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 8;
    .rule-box {
      position: relative;
      width: 60%;
      background: rgba(231, 251, 255, 0.5);
      padding: 4rem 11rem 3rem 2.5rem;
      border-radius: 2rem;
      .time {
        width: 24.8rem;
        height: 9.15rem;
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url('../assets/img/cloud.png');
        background-size: 100%;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        .start-text {
          font-size: 1.5rem;
        }
        span {
          font-size: 1.8rem;
          margin: 0 1rem;
          &.name {
            font-size: 1.2rem;
            margin: 0;
          }
        }
      }
      .rule-title {
        font-size: 2.2rem;
        margin-bottom: 1.6rem;
        div {
          margin: -0.2rem 0 0 1rem;
        }
      }
      .rule-content {
        font-size: 1.2rem;
        line-height: 2.2rem;
      }
      .rule-layer {
        margin-top: 1rem;
        .layer-row {
          display: flex;
          // align-items: center;
          font-size: 1.4rem;
          margin: 1.1rem 0;
          .layer-index {
            width: 1.5rem;
            height: 1.5rem;
            // line-height: 1.5rem;
            margin-top: 0.1rem;
            border-radius: 1.5rem;
            background-color: #D0F5FD;
            border: 0.1rem solid #0080BF;
            text-align: center;
            font-size: 1.2rem;
          }
          .layer-title {
            margin: 0 1.8rem 0 0.6rem;
            font-weight: bold;
            flex-shrink: 0;
          }
        }
      }
    }
    .exchange-box {
      margin-left: -10%;
      width: 40%;
      max-width: 26rem;
      background-color: #E7FBFF;
      border-radius: 2rem;
      overflow: hidden;
      z-index: 10;
      .exchange-title {
        font-size: 1.8rem;
        font-weight: bold;
        text-align: center;
        padding: 0.8rem 0;
        background-color: #0080BF;
        color: #fff;
      }
      .exchange-content {
        padding: 1.6rem 1rem;
        color: #404040;
        font-size: 1rem;
        .exchange-input {
          position: relative;
          height: 2.8rem;
          line-height: 2.8rem;
          box-sizing: border-box;
          margin-top: 0.6rem;
          border: 0.15rem solid #0080BF;
          border-radius: 0.5rem;
          /deep/ .el-input {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            .el-input__inner {
              background-color: transparent;
              border: none;
              font-size: 1rem;
              height: 100%;
              line-height: 100%;
              input {
                height: 100%;
                line-height: 100%;
              }
            }
            .el-input-group__append {
              background-color: #A5E4FF;
              padding: 0 0.8rem;
              color: #0080BF;
              font-size: 0.9rem;
            }
          }
        }
        .exchange-info {
          text-align: center;
          line-height: 2rem;
          color: #0080BF;
          margin: 1.4rem 0;
        }
        .exchange-button {
          text-align: center;
          .el-button {
            width: 9rem;
            height: 2rem;
            line-height: 1.6rem;
            border-radius: 2rem;
            color: #fff;
            padding: 0;
            font-size: 1rem;
            background-color: #0080BF;
          }
        }
      }
    }
  }
  .bg-wrapper {
     position: absolute;
     top: 0px;
     left: 0px;
     z-index: 6;
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     img {
       width: 100%;
     }
  }
}

</style>