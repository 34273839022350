import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    account: '',
    approved: false,
    buyStatus: false
  },
  getters: {
    account(state) {
      return state.account;
    },
    approved(state) {
      return state.approved;
    },
    returnBuyStatus(state) {
      return state.buyStatus;
    }
  },
  mutations: {
    updateBuy(state, val) {
      state.buyStatus = val;
    }
  },
  actions: {
  },
  modules: {
  }
})
