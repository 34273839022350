module.exports = {
  app: {
    WALLET: '连接钱包',
  },
  nav: {
    HOME: '首页',
    FARM: '农场',
    EXCHANGE: '兑换',
    GAME: '游戏',
    BRIDGE: '跨链桥',
    MARKET: 'NFT 市场',
    TWITTER: '推特',
    GITHUB: 'GITHUB',
    WHITEBOOK: '文档',
    AUDIT: '审计'
  },
  home: {
    BALANCE: '您的 SFI 数量',
    SUPPLY: 'SFI 总量',
    BURNED: '发布以来总共燃烧',
    DISTRIBUTED: '流通价值',
    USERVALUE: '您的 SFI 价值',
    VLAUE: 'SFI 总价值'
  },
  farm: {
    UNLOCK: '打开钱包',
    DETAILS: '细节',
    EARNED: '已赚取SFI',
    LPSTAKED: '已质押的',
    TAKE: '可质押的',
    GETBENIFIT: '领取收益',
    CANWITHDRAW: '可提现的',
    PLEDGE: '质押',
    WITHDRAW: '提现',
    LIQUIDITY: '总流通量',
    GET: '去获得',
    VIEW: '在BscScan上查看'
  },
  exchange: {
    RULES: '兑换规则',
    LINE1: '1000万枚SFI通过兑换发行，用户使用USDT参与兑换。',
    LINE2: '兑换分为3层，当层兑换完毕智能合约自动开启下一层兑换，兑换价格按规定逐层递增。',
    LINE3: '兑换的最小单位为2000枚SFI，每次兑换数额为2000的增数倍，一个钱包地址最大兑换数额为20份。',
    LINE4: '兑换成功以后我们的钱包里，会得到你用USDT兑换来的SFI。',
    LAYER1: '第一层',
    LAYER2: '第二层',
    LAYER3: '第三层',
    PRICE1: '334万枚单价',
    PRICE2: '333万枚单价',
    PRICE3: '333万枚单价',
    BALANCE: 'USDT余额',
    EXCHANGE: '交换',
    SINGLE: '单路输入最多为20',
    EXPLAIN: '使用USDT兑换SFI，兑换金额必须是1000的整数倍',
    CURRENT1: '当前价格为',
    CURRENT2: '会涨到',
    APPROVAL: '批准',
    BEGIN: '预售已开始',
    END: '预售结束',
    BUY: '购买',
    DAYS: '天',
    HOURS: '时',
    MINUTES: '分',
    SECONDES: '秒'
  },
  game: {
    COMING: '即将推出',
    INTRODUCTION: ' 这是一场海底世界的远征，在这里可以施展各种捕鱼技能，享受捕获大鱼的乐趣，但不是所有的鱼都是友善的，它们会用自己的方式保护自己，保卫属于自己的海底世界。当然，这里也是冒险与机遇共存的地方，诸多埋藏于海底的宝藏等待着被探寻。'
  }
};